<template>
  <b-row v-if="!applyOverlay">
    <!-- Poder -->
    <b-col md="12">
      <b-row>
        <b-col md="6">
          <h5 class="mt-1">
            <strong>{{ $t('clubs.powers.powerOfAttorneyData') }}</strong>
          </h5>
        </b-col>
        <b-col md="6">
          <span
            v-if="nPowers > 1 && checkPermission(['DELETE_POWER_OF_ATTORNEY', 'ALLOW_ALL'])"
            class="float-right mt-1 ml-1"
          >
            {{ $t( 'buttons.delete') }}
            <b-button
              class="btn-icon rounded-circle"
              variant="danger"
              @click="$emit('delete-power', actions.powerId)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </span>
          <span
            v-if="checkPermission(['EDIT_POWER_OF_ATTORNEY', 'ALLOW_ALL'])"
            class="float-right mt-1"
          >
            {{ $t( 'buttons.edit') }}
            <b-button
              class="btn-icon rounded-circle"
              variant="secondary"
              @click="$emit('edit-power', actions.powerId)"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
          </span>
        </b-col>
      </b-row>
      <hr style="margin-top: 8px;">
    </b-col>
    <b-col md="12">
      <b-list-group>
        <b-list-group-item>
          <b-row>
            <b-col md="6">
              <strong class="float-left">{{ $t('clubs.powers.form.instrumentDate') }}</strong>
            </b-col>
            <b-col md="6">
              <span class="float-left">{{ powerInfo.instrument_date | formatDate() }}</span>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col md="6">
              <strong class="float-left">{{ $t('clubs.instrumentNumber.labels.countryOfNotary') }}</strong>
            </b-col>
            <b-col md="6">
              <span class="float-left">{{ powerInfo.instrument_number.country.labelables[0].label }}</span>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col md="6">
              <strong class="float-left">{{ $t('clubs.instrumentNumber.labels.stateOfNotary') }}</strong>
            </b-col>
            <b-col md="6">
              <span class="float-left">{{ powerInfo.instrument_number.state.name }}</span>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col md="6">
              <strong class="float-left">{{ $t('clubs.instrumentNumber.labels.notaryName') }}</strong>
            </b-col>
            <b-col md="6">
              <span class="float-left">{{ powerInfo.instrument_number.notary_name }}</span>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col md="6">
              <strong class="float-left">{{ $t('clubs.instrumentNumber.labels.notaryNumber') }}</strong>
            </b-col>
            <b-col md="6">
              <span class="float-left">{{ powerInfo.instrument_number.notary_number }}</span>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col md="6">
              <strong class="float-left">{{ $t('generic.document') }}</strong>
            </b-col>
            <b-col md="6">
              <span>. . .</span>
              <b-media
                class="float-right"
                no-body
              >
                <span
                  class="i-c-pointer cursor-pointer float-right"
                  @click="showFile = !showFile"
                >
                  {{ $t('generic.see') }}
                  <feather-icon
                    v-if="!showFile"
                    icon="FileIcon"
                    size="17"
                    style="padding-bottom: 2px"
                  />
                </span>
                <div
                  v-if="showFile == true"
                  class="ml-2"
                  style="display: flex;"
                >
                  <div
                    v-if="checkPermission(['VIEW_CONTRACT_FILE_MEMBERS', 'ALLOW_ALL']) && showFile == true"
                    class="float-right"
                    style="display: flex;"
                  >
                    <div v-if="isObject(powerInfo.document_media_file) && powerInfo.document_media_file.mime_type != 'application/pdf'">
                      <image-viewer
                        class="cursor-pointer mr-1"
                        :document-id="powerInfo.document_file_hash"
                        :url-thumb="powerInfo.document_media_file.url_thumb"
                        :height="'30'"
                        :width="'30'"
                        :permission-show="'VIEW_CONTRACT_FILE_MEMBERS'"
                        :permission-download="'DOWNLOAD_CONTRACT_FILE_MEMBERS'"
                      />
                    </div>
                    <div v-else>
                      <ViewerDocumentModal
                        class="cursor-pointer mr-1"
                        :title="powerInfo.document_media_file ? powerInfo.document_media_file.title : ''"
                        :document-id="powerInfo.document_file_hash"
                        :height="'30'"
                        :width="'30'"
                        :permission-show="'VIEW_CONTRACT_FILE_MEMBERS'"
                        :permission-download="'DOWNLOAD_CONTRACT_FILE_MEMBERS'"
                      />
                    </div>
                    <div v-if="powerInfo.document_file_hash != null && checkPermission(['DOWNLOAD_CONTRACT_FILE_MEMBERS', 'ALLOW_ALL'])">
                      <span
                        class="float-right cursor-pointer"
                        @click="handleDownloadFile(powerInfo.document_file_hash, powerInfo.document_media_file.title)"
                      >
                        <i
                          class="icon-0-icons-dark-download btn-cursor"
                          style="font-size: 20px;"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </b-media>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col md="6">
              <strong class="float-left">{{ $t('clubs.powers.form.observations') }}</strong>
            </b-col>
            <b-col md="6">
              <span>. . .</span>
              <span
                v-b-modal.modal-xl-rt-p
                class="i-c-pointer float-right"
              >
                {{ $t('generic.see') }}
                <feather-icon
                  icon="EyeIcon"
                  size="17"
                  style="padding-bottom: 2px"
                />
              </span>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </b-col>
    <!--/ Poder -->
    <!-- Modal -->
    <b-modal
      id="modal-xl-rt-p"
      no-close-on-backdrop
      centered
      size="xl"
      :title="$t('clubs.powers.form.observations')"
    >
      <!-- texto enriquecido -->
      <rich-text-edit-p
        :text-one="$t('clubs.powers.form.observations')"
        :text-two="'observations'"
        :agreements="powerInfo.observations"
        :updating-data="false"
        :apply-edition-x="'edit'"
        :read-only-text="true"
      />
      <!--/ texto enriquecido -->
      <template #modal-footer>
        <div class="w-100" />
      </template>
    </b-modal>
    <!--/ Modal -->
    <login-modal
      :permission="validPermission"
      @change="toAccess"
    />
  </b-row>
  <b-row v-else>
    <b-col
      md="12"
      class="i-height-div"
    />
  </b-row>
</template>

<script>
/* eslint-disable object-curly-newline */
import { mapActions } from 'vuex'
import { isObject, downloadFile } from '@core/utils/utils'
import { BRow, BCol, BListGroup, BListGroupItem, BButton, BModal, VBModal } from 'bootstrap-vue'
import powerOAService from '@/services/powerOfAttorneyService'
import RichTextEditP from '@/views/partials/editors/RichTextEdit.vue'
import ImageViewer from '@/views/member/ImageViewer.vue'
import ViewerDocumentModal from '@/views/member/ViewerDocumentModal.vue'
import LoginModal from '@/views/member/LoginModal.vue'

export default {
  components: {
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BButton,
    BModal,
    RichTextEditP,
    ImageViewer,
    ViewerDocumentModal,
    LoginModal,
  },

  directives: {
    'b-modal': VBModal,
  },

  props: {
    applyOverlay: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Object,
      default: () => {},
    },
    nPowers: {
      type: Number,
      default: null,
    },
    checkPermission: {
      type: Function,
      default: null,
    },
  },

  data() {
    return {
      validPermission: null,
      showFile: false,
      power: {},
      powerInfo: {},
    }
  },

  watch: {
    'actions.powerId': function () {
      this.power = {}
      this.fetchPOAInfo()
    },
  },

  methods: {
    downloadFile,
    isObject,
    ...mapActions({ mediaFileShow: 'mediaFile/show' }),

    fetchPOAInfo() {
      powerOAService.getPowerInfo(this.actions.powerId).then(({ data }) => {
        this.powerInfo = data.data
        this.$emit('stop-overlay')
      }).catch(error => {
        this.$emit('stop-overlay')
        this.responseCatch(error)
      })
    },

    async toAccess(permissionData) {

    },

    async handleDownloadFile(fileHash, title) {
      this.isLoading = true
      await this.mediaFileShow(fileHash).then(response => {
        const { data: dataDocument } = response
        this.downloadFile(dataDocument, title)
        this.isLoading = false
      }).catch(error => {
        this.isLoading = false
        this.responseCatch(error)
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
}
</script>

<style scoped>
.i-c-pointer:hover {
  color: #6abeff;
}
</style>
